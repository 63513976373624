import Vue from 'vue'
import  Router from 'vue-router'
Vue.use(Router);
import pageindex from './routers/pages'


const baseRoutes = [
    ...pageindex,
];

const createRouter = () => new Router({
    mode:"hash",
    routes: baseRoutes
  })
const router = createRouter()
  

export default router