let routes = [
	{
		path: '/',
		name: '/',
		redirect:"/pay"
	},
	{
		path: '/pay',
		name: '/pay',
		component: () => import('@/view/pay/pay.vue'),
		meta: {
			title: '支付',
			keepAlive: true
		}
	},
	{
		path:'/back',
		name:'/back',
		component:()=>import('@/view/pay_status/pay_status.vue'),
		meta: {
			title: '支付',
			keepAlive: true
		}
	}
];

export default routes;